/* eslint-disable max-len */
import * as React from 'react';
import * as yup from 'yup';
import {faInfoCircle} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {FormProvider, useForm} from 'react-hook-form';
import {useState} from 'react';
import {yupResolver} from '@hookform/resolvers/yup';

import Button from '../../ui/Buttons/Button';
import {CheckBox} from '../../ui/forms/CheckBox/CheckBox';
import {Input} from '../../ui/forms/Input/Input';
import {ToolTip} from '../../ui/Tooltip/Tooltip';
import {validateLoginName, validatePassword} from '../../tools/yup-validators.functions';

interface LoginFormValues {
    login: string;
    Password: string;
    stayauthed: boolean;
}

interface LoginFormProps {
    csrfToken: string;
    loginName: string;
    reauthenticate: boolean;
    url: string;
}

export const LoginForm = ({csrfToken, loginName, reauthenticate, url}: LoginFormProps) => {
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const formValidation = yup.object().shape({
        login: validateLoginName(),
        Password: validatePassword(),
    });
    const formInitialValues: LoginFormValues = {
        login: loginName,
        Password: ``,
        stayauthed: true,
    };
    const useFormReturn = useForm({
        defaultValues: formInitialValues,
        mode: `onChange`,
        resolver: yupResolver(formValidation),
    });
    const stayauthedValue = useFormReturn.watch(`stayauthed`);

    /**
     * Template
     */
    return (
        <FormProvider {...useFormReturn}>
            <form
                action="/logon"
                data-binding="loginForm"
                method="post"
                name="login"
            >
                <input
                    name="_csrf"
                    type="hidden"
                    value={csrfToken}
                />
                <input
                    name="loginType"
                    type="hidden"
                    value="page"
                />
                <input
                    name="reauthenticate"
                    type="hidden"
                    value={reauthenticate?.toString()}
                />
                <input
                    name="url"
                    type="hidden"
                    value={url}
                />
                {reauthenticate ? (
                    <>
                        <input
                            name="login"
                            type="hidden"
                            {...useFormReturn.register(`login`)}
                            value={loginName}
                        />
                        <div className="tw-mb-6 maskPII">Email: {loginName}</div>
                    </>
                ) : (
                    <Input
                        className="maskPII"
                        error={useFormReturn?.formState.errors?.login?.message}
                        id="loginEmail"
                        label="Email"
                        name="login"
                        required
                        type="text"
                        {...useFormReturn.register(`login`)}
                    />
                )}
                <div className="tw-relative tw-font-normal">
                    <Input
                        {...useFormReturn.register(`Password`)}
                        autoComplete="none"
                        className="maskPII"
                        error={useFormReturn?.formState.errors?.Password?.message}
                        label="Password"
                        name="Password"
                        onBlur={() => {
                            void useFormReturn.trigger(`Password`);
                        }}
                        onShowPassword={(showPass) => {
                            setShowPassword(!showPass);
                        }}
                        required
                        showPass={showPassword}
                        type="password"
                    />
                </div>
                <div className="tw-text-right tw-mt-0">
                    {!reauthenticate && (
                        <>
                            <a
                                className="tw-mr-2"
                                href="/create-account?existingAccount=true"
                            >
                                Request Web Access
                            </a>
                            {' |'}
                        </>
                    )}
                    <a
                        className="tw-ml-2"
                        href="/forgot-password"
                    >
                        Reset Password
                    </a>
                </div>
                {!reauthenticate && (
                    <div className="tw-my-4">
                        <div className="tw-relative tw-z-index-[1] tw-block tw-min-h-[1.5rem]">
                            <CheckBox
                                alias="loginSaveChkbox"
                                defaultChecked={true}
                                error={useFormReturn?.formState.errors?.stayauthed?.message}
                                label={
                                    <>
                                        Keep me signed in
                                        <ToolTip
                                            id="loginTooltip"
                                            title="If the 'Keep me signed in' box is checked, you will stay logged in for one year, or until you attempt to carry out a sensitive action, such as accessing business reports. We strongly recommend you don't use this feature on devices and computers you share with other people."
                                        >
                                            <FontAwesomeIcon
                                                className="tw-text-gray-400 tw-mt-0.5 tw-ml-2"
                                                icon={faInfoCircle}
                                            />
                                        </ToolTip>
                                    </>
                                }
                                name="stayauthed"
                                value={stayauthedValue ? `on` : `off`}
                                {...useFormReturn.register(`stayauthed`)}
                            />
                        </div>
                    </div>
                )}
                <Button
                    block={true}
                    className="tw-mt-4 tw-mb-6"
                    disabled={!useFormReturn?.formState.isValid}
                    size="lg"
                    type="submit"
                    variant="primary"
                >
                    Log In
                </Button>
                {!reauthenticate && (
                    <Button
                        className="tw-w-full tw-mb-2"
                        onClick={() => location.assign('/create-account')}
                        size="lg"
                        variant="outline-secondary"
                    >
                        Create Account
                    </Button>
                )}
            </form>
        </FormProvider>
    );
};
